import React, {useContext} from "react"
import {Accordion} from "../Accordion"
import MusicContext from "../context"

export const CardSound = () => {
  const {_sounds, _artists} = useContext(MusicContext)

  if (!_artists.data || !_sounds.data) {
    return <p>loading</p>
  }
  const sounds = _artists.data.map((item, index) => {
    const data = _sounds.data.filter((t) => t.artist === item.name)
    return (
      <Accordion key={item.slug} item={item} data={data} index_artist={index} />
    )
  })

  return (
    <div className="accordion" id="accordionExample">
      {sounds}
    </div>
  )
}
