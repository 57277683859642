import React from "react"

export const Menu = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="https://kabai.ru/">
          Кабай
        </a>
      </div>
    </nav>
  )
}
