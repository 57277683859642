import React, {useEffect, useState, useContext, useRef} from "react"
import ReactPlayer from "react-player"
import MusicContext from "../context"
import styles from "./Player.module.scss"

const formatTime = (seconds) => {
  let minutes = Math.floor(seconds / 60)
  minutes = minutes >= 10 ? minutes : "0" + minutes
  seconds = Math.floor(seconds % 60)
  seconds = seconds >= 10 ? seconds : "0" + seconds
  return minutes + ":" + seconds
}

export const Player = () => {
  const {sound, getSound, setActive, __artist, _artists} =
    useContext(MusicContext)
  const player = useRef()
  const [durations, setDutations] = useState(null)
  const [elapsed, setElapsed] = useState(null)
  const [playing, setPlaying] = useState(false)
  const [playeds, setPlayeds] = useState(0)

  const onProgress = (progress) => {
    progress && setPlayeds(progress.played)
    progress && setElapsed(progress.played * durations)
  }
  const togglePlay = () => setPlaying(!playing)
  const nextTrack = () => sound.next && getSound(sound.next)
  const prevTrack = () => sound.prev && getSound(sound.prev)
  const onDuration = (duration) => setDutations(duration)

  const onChangeInput = (event) => {
    player.current.seekTo(durations * event.target.value)
  }

  const onEnded = () => {
    sound.next ? nextTrack() : setActive(_artists.data[__artist + 1].name)
  }

  return (
    <footer className={styles.footer}>
      <ReactPlayer
        ref={player}
        playing={playing}
        url={!sound ? "" : `https://api.kabai.ru${sound.file}`}
        height={0}
        controls={true}
        onDuration={onDuration}
        onProgress={onProgress}
        onEnded={onEnded}
        style={{
          display: "none",
        }}
        config={{
          file: {
            attributes: {
              autoPlay: true,
              // muted: true,
            },
          },
        }}
      />
      <div className={styles.info}>
        <div className={styles.info__text}>
          <span>{!sound ? "Track" : sound.track}</span>
          <span>{!sound ? "Artist" : sound.artist}</span>
        </div>
        <div className={styles.player}>
          <i className={styles.player__prev} onClick={prevTrack}>
            skip_previous
          </i>
          <i className={styles.player__play} onClick={togglePlay}>
            play_circle_filled
          </i>
          <i className={styles.player__ff} onClick={nextTrack}>
            skip_next
          </i>
        </div>
        <div className={styles.img}>
          <div className={styles.img__icon} />
        </div>
      </div>
      <div className={styles.timeline}>
        <span className={styles.timeline__time}>{formatTime(elapsed)}</span>
        <input
          className={styles.timeline__input}
          type="range"
          id="timeline"
          name="timeline"
          min="0"
          max="1"
          step="0.001"
          value={playeds}
          onChange={onChangeInput}
        />
        <span className={styles.timeline__time}>{formatTime(durations)}</span>
      </div>
    </footer>
  )
}
