import axios from "axios"
import {useState, useEffect} from "react"

export default (url, name) => {
  const [data, setData] = useState()
  const [error, setError] = useState(null)

  useEffect(() => {
    const ls = localStorage.getItem(name)
    if (ls) {
      setData(JSON.parse(ls).data)
    }
    axios
      .get(`https://api.kabai.ru/${url}`)
      .then(function (response) {
        localStorage.setItem(name, JSON.stringify(response))
        setData(response.data)
      })
      .catch(function (error) {
        setError(error)
      })
  }, [])
  return {data, error}
}
