import React, {useEffect, useState} from "react"
import {Player} from "../Player"
import {CardSound} from "../cards/CardSound"
import MusicContext from "../context"
import useFetch from "../../api/useFetch"
import axios from "axios"

export const Features = () => {
  const _artists = useFetch("music/v1/artist/", "_artist")
  const _sounds = useFetch("music/v1/sound/", "_sounds")
  const [active, setActive] = useState(null)
  const [sound, setSound] = useState(null)
  const data = _sounds.data && _sounds.data.filter((x) => x.artist === active)
  const __artist =
    active && _artists.data.findIndex((element) => element.name === active)

  const getSound = (val) => {
    if (val) {
      axios
        .get(`https://api.kabai.ru/music/v1/sound/${val.slug}/`)
        .then(function (response) {
          const d = response.data
          d.prev = data[data.indexOf(val) - 1]
          d.next = data[data.indexOf(val) + 1]
          setSound(d)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
    return
  }

  useEffect(() => {
    if (active) {
      getSound(data[0])
    }
  }, [active])

  return (
    <MusicContext.Provider
      value={{
        sound,
        _artists,
        _sounds,
        getSound,
        active,
        setActive,
        __artist,
      }}
    >
      <div className="container-fluid d-flex justify-content-center">
        <div className="col-lg-8">
          <div className="accordion" id="accordionExample">
            <CardSound />
          </div>
        </div>
      </div>
      <Player />
    </MusicContext.Provider>
  )
}
