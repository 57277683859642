import React, {useContext} from "react"
import MusicContext from "../context"
import styles from "./Accordion.module.scss"

export const Accordion = ({item, data}) => {
  const {sound, getSound, active, setActive} = useContext(MusicContext)

  const onSound = (item) => {
    getSound(item)
  }

  const tracks = data.map((item, index) => {
    let img = `https://kabai.ru${item.photo}`
    return (
      <div onClick={() => onSound(item)} key={item.slug}>
        <div className={styles.item}>
          <div className={styles.item__number}>
            {sound && sound.slug === item.slug ? (
              <i className={styles.item__play}>play_arrow</i>
            ) : (
              index + 1
            )}
          </div>
          <div className={styles.item__sound}>
            {!item.photo ? (
              <div className={styles.item__photo_default} />
            ) : (
              <div
                className={styles.item__photo}
                style={{backgroundImage: url(img)}}
              />
            )}

            <div className={styles.item__track}>
              <p>{item.track}</p>
              <p>{item.artist}</p>
            </div>
          </div>
          <div className={styles.item__view}>{item.views}</div>
        </div>
      </div>
    )
  })

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <div className={styles.artist} onClick={() => setActive(item.name)}>
          <p>{item.name}</p>
          <p>{data.length}</p>
        </div>
      </h2>
      <div
        id={item.slug}
        className={
          "accordion-collapse collapse" + (active === item.name ? "show" : "")
        }
      >
        <div>{tracks}</div>
      </div>
    </div>
  )
}
