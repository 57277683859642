import "./utils/App.css"
import "./utils/style.css"
import {Menu} from "./components/Content/Menu"
import {Features} from "./components/Content"

function App() {
  return (
    <div className="App">
      <Menu />
      <Features />
    </div>
  )
}

export default App
